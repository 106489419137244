<template>
  <v-app>
    <BasicInfoCard />
  </v-app>
</template>

<script>
import BasicInfoCard from "@/components/contract-profile/BasicInfoCard.vue";

export default {
  components: {
    BasicInfoCard,
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <v-dialog v-model="dialog" persistent max-width="800px">
    <template #activator="{ on }">
      <slot :on="on"></slot>
    </template>
    <v-card class="p-5 p-md-8">
      <ModalHeading modalTitle="Reject Renewal" />
      <form class="form pt-3" @submit.prevent="submitForm">
        <div class="row">
          <div class="col-md-12">
            <label class="form__label"
              >The Reason of Rejection <span class="text-danger">*</span></label
            >
            <v-select
              dense
              outlined
              v-model.trim="form.reason"
              @blur="$v.form.reason.$touch()"
              :items="reasonsList"
              class="v-select-input__renewal-type"
            >
              <template v-slot:selection="{ attr, on, item, selected }">
                <v-chip
                  v-bind="attr"
                  :input-value="selected"
                  color="grey lighten-2"
                  v-on="on"
                  class="mb-1"
                >
                  <span v-text="item.name"></span>
                </v-chip>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title
                    v-text="`${item.name}`"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>
            </v-select>
            <VulidateError
              :validationField="$v.form.reason"
              :params="['required']"
            />
          </div>
          <div class="col-md-12 mb-8">
            <label class="form__label">Enter your comment</label>
            <v-textarea
              outlined
              dense
              hide-details
              rows="3"
              :no-resize="true"
              v-model.trim="form.comment"
              @blur="$v.form.comment.$touch()"
            ></v-textarea>
            <VulidateError
              v-if="$v.form.comment?.$dirty && !$v.form.comment.maxLength"
              :validationField="$v.form.comment"
              :params="['required', { maxLength: { number: '5000' } }]"
            />
            <div
              class="form__error-label"
              v-if="$v.form.comment?.$dirty && !$v.form.comment.required"
            >
              Please enter the other reason in the comments section.
            </div>
          </div>
        </div>
        <div class="text-right">
          <button
            class="btn btn-secondary font-weight-bolder font-size-h6 px-8"
            type="button"
            @click="resetModal"
          >
            Cancel
          </button>
          <button
            class="btn btn-info font-weight-bolder font-size-h6 px-8 ml-5"
            :class="{
              'spinner spinner-light spinner-right': isLoadingSubmitte,
            }"
            :disabled="isLoadingSubmitte"
          >
            Save
          </button>
        </div>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import ModalHeading from "@/components/UI/ModalHeading.vue";
import VulidateError from "@/components/shared/vulidateError.vue";
import { validationMixin } from "vuelidate";
import { required, maxLength, requiredIf } from "vuelidate/lib/validators";

export default {
  components: { ModalHeading, VulidateError },
  props: {
    type: {
      type: Number,
      default: 2,
    },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      reason: {
        required,
      },
      comment: {
        maxLength: maxLength(5000),
        required: requiredIf(function () {
          return this.form.reason && this.form.reason.id === 0;
        }),
      },
    },
  },
  mounted() {
    this.getReasons();
  },
  data() {
    return {
      dialog: false,
      isLoading: false,
      formMode: "add",
      isLoadingSubmitte: false,
      reasonsList: [],
      form: {
        reason: null,
        comment: "",
      },
    };
  },
  watch: {
    type: {
      deep: true,
      handler() {
        this.getReasons();
      },
    },
  },
  methods: {
    ...mapActions({
      getContractRejectionReason: "contracts/getContractRejectionReason",
      renewContract: "contracts/renewContract",
    }),
    async getReasons() {
      this.reasonsList = await this.getContractRejectionReason({
        type: this.type,
      });
    },
    submitForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.isLoadingSubmitte = true;
      let payload = {
        contract_id: Number(this.$route.params.id),
        data: {
          renewal_state: this.type == 2 ? "client_rejected" : "talent_rejected",
          reason: this.form.reason.id,
          comment: this.form.comment,
        },
      };
      this.renewContract(payload)
        .then(() => {
          this.isLoadingSubmitte = false;
          this.resetModal();
          this.$emit("renewalRejected");
        })
        .catch(() => {
          this.isLoadingSubmitte = false;
        });
    },
    resetModal() {
      this.dialog = false;
      this.$v.$reset();
      this.form = {
        reason: null,
        comment: "",
      };
    },
  },
};
</script>

<template>
  <v-dialog v-model="dialog" persistent max-width="800px">
    <template #activator="{ on }">
      <slot :on="on"></slot>
    </template>
    <v-card class="p-5 p-md-8" v-if="contract">
      <ModalHeading :modalTitle="'Edit Contract Terms'" />
      <form class="form pt-3" @submit.prevent="submitForm">
        <div class="row">
          <div class="col-12 mt-7">
            <div class="row">
              <div class="col-6">
                <label class="form__label"
                  >Contract Type <span class="text-danger">*</span></label
                >
                <v-select
                  dense
                  outlined
                  v-model="contractType"
                  :items="contractTypeList"
                  class="v-select-input__contract-type"
                >
                  <template v-slot:selection="{ attr, on, item, selected }">
                    <v-chip
                      v-bind="attr"
                      :input-value="selected"
                      color="grey lighten-2"
                      v-on="on"
                      class="mb-1"
                    >
                      <span v-text="item.name"></span>
                    </v-chip>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="`${item.name}`"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select>
                <VulidateError
                  :validationField="$v.contractType"
                  :params="['required']"
                />
              </div>
              <div class="col-6">
                <label class="form__label"
                  >Job Type <span class="text-danger">*</span></label
                >
                <v-select
                  dense
                  outlined
                  v-model="jobType"
                  :items="jobTypeList"
                  class="v-select-input__contract-type"
                >
                  <template v-slot:selection="{ attr, on, item, selected }">
                    <v-chip
                      v-bind="attr"
                      :input-value="selected"
                      color="grey lighten-2"
                      v-on="on"
                      class="mb-1"
                    >
                      <span v-text="item.text"></span>
                    </v-chip>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="`${item.text}`"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select>
                <VulidateError
                  :validationField="$v.jobType"
                  :params="['required']"
                />
              </div>
            </div>
          </div>
          <div class="col-12 mt-7">
            <div class="row">
              <div class="col-6">
                <label class="form__label"
                  >Monthly Salary (USD)
                  <span class="text-danger">*</span></label
                >
                <v-text-field
                  type="number"
                  outlined
                  prepend-icon="mdi-coin"
                  :disabled="
                    !(contract.contract_type === 2 && contractType === 1)
                  "
                  v-model.number="monthlySalary"
                  @blur="$v.monthlySalary.$touch()"
                  @input="$v.monthlySalary.$touch()"
                ></v-text-field>
                <VulidateError
                  :validationField="$v.monthlySalary"
                  :params="[
                    'required',
                    'decimal',
                    {
                      minValue: {
                        number: $v.monthlySalary.$params.minValue.min,
                      },
                    },
                    {
                      maxValue: {
                        number: $v.monthlySalary.$params.maxValue.max,
                      },
                    },
                  ]"
                />
              </div>
              <div class="col-6">
                <label class="form__label">Raise Amount</label>
                <v-text-field
                  type="number"
                  outlined
                  prepend-icon="mdi-coin"
                  :disabled="contract?.renewal_terms.raise_applied"
                  v-model.number="raise"
                  @blur="$v.raise.$touch()"
                  @input="$v.raise.$touch()"
                ></v-text-field>
                <VulidateError
                  :validationField="$v.raise"
                  :params="[
                    'decimal',
                    {
                      minValue: {
                        number: 0,
                      },
                    },
                  ]"
                />
              </div>
            </div>
          </div>
          <div class="col-12 mt-7">
            <div class="row">
              <div class="col-12">
                <label class="form__label"
                  >Margin (USD) <span class="text-danger">*</span></label
                >
                <v-text-field
                  type="number"
                  outlined
                  prepend-icon="mdi-coin"
                  v-model.number="margin"
                  @blur="$v.margin.$touch()"
                  @input="$v.margin.$touch()"
                ></v-text-field>
                <VulidateError
                  :validationField="$v.margin"
                  :params="[
                    'required',
                    'decimal',
                    {
                      minValue: {
                        number: 0,
                      },
                    },
                  ]"
                />
              </div>
              <div class="col-6" v-if="false">
                <label class="form__label">
                  WHT (USD) <span class="text-danger"></span>
                </label>
                <v-text-field
                  type="number"
                  outlined
                  prepend-icon="mdi-coin"
                  v-model.number="wht"
                  disabled
                ></v-text-field>
              </div>
            </div>
          </div>
          <div class="col-12 mt-7">
            <div class="row">
              <div class="col-6">
                <label class="form__label"
                  >Start Date <span class="text-danger">*</span></label
                >

                <v-dialog
                  ref="startDataDialog"
                  v-model="startDataMenu"
                  :return-value.sync="startDate"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="startDate"
                      outlined
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>

                  <v-date-picker
                    v-model="startDate"
                    :max="
                      endData && endData.length > 0
                        ? new Date(
                            new Date(endData).setDate(
                              new Date(endData).getDate() - 1
                            )
                          )
                            .toISOString()
                            .substr(0, 10)
                        : endData
                    "
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="startDataMenu = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.startDataDialog.save(startDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
                <VulidateError
                  :validationField="$v.startDate"
                  :params="['required']"
                />
              </div>
              <div class="col-6">
                <label class="form__label"
                  >End Date <span class="text-danger">*</span></label
                >
                <v-dialog
                  ref="endDataDialog"
                  v-model="endDataMenu"
                  :return-value.sync="endData"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="endData"
                      outlined
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="endData"
                    :min="
                      startDate
                        ? new Date(
                            new Date(startDate).setDate(
                              new Date(startDate).getDate() + 1
                            )
                          )
                            .toISOString()
                            .substr(0, 10)
                        : null
                    "
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="endDataMenu = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.endDataDialog.save(endData)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
                <VulidateError
                  :validationField="$v.endData"
                  :params="['required']"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="text-right">
          <button
            class="btn btn-secondary font-weight-bolder font-size-h6 px-8"
            type="button"
            @click="resetModal"
          >
            Cancel
          </button>
          <button
            class="btn btn-info font-weight-bolder font-size-h6 px-8 ml-5"
            :class="{
              'spinner spinner-light spinner-right': isLoadingSubmitte,
            }"
            :disabled="isLoadingSubmitte"
          >
            Save
          </button>
        </div>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import ModalHeading from "@/components/UI/ModalHeading.vue";
import VulidateError from "@/components/shared/vulidateError.vue";
import { SALARY_MIN_VALUE, SALARY_MAX_VALUE } from "@/constants/salary";
import {
  required,
  decimal,
  minValue,
  maxValue,
} from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";

export default {
  name: "AddClientToTalent",
  components: { ModalHeading, VulidateError },
  mixins: [validationMixin],

  props: {
    contract: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
      isLoading: false,
      isLoadingSubmitte: false,
      searchQuery: null,
      monthlySalary: null,
      margin: null,
      wht: null,
      raise: null,
      startDate: null,
      endData: null,
      contractType: null,
      jobType: null,
      startDataMenu: false,
      endDataMenu: false,
      contractTypeList: [
        {
          name: "Full time",
          value: 2,
        },
        {
          name: "Part time",
          value: 1,
        },
        {
          name: "Monthly Hours",
          value: 3,
        },
      ],
      renewalTypeList: [
        {
          name: "Auto",
          value: "auto",
        },
        {
          name: "Manual",
          value: "manual",
        },
      ],
      jobTypeList: [
        {
          text: "Remote",
          value: "remote",
        },
        {
          text: "Onsite - Iqama",
          value: "onsite-accommodation",
        },
        {
          text: "Onsite - Business Visa",
          value: "onsite-business-visa",
        },
      ],
    };
  },
  watch: {
    contract(newVal) {
      if (newVal) {
        this.startDate = newVal.renewal_terms.start_date;
        this.endData = newVal.renewal_terms.end_date;
        this.contractType = newVal.renewal_terms.contract_type;
        this.monthlySalary = newVal.renewal_terms.salary.basic_salary;
        this.margin = newVal.renewal_terms.salary.margin;
        this.wht = newVal.renewal_terms.salary.wht_value;
        this.jobType = newVal.renewal_terms.job_type;
        this.raise = newVal.renewal_terms.raise;
      }
    },
  },
  validations: {
    startDate: { required },
    endData: { required },
    contractType: { required },
    jobType: { required },
    raise: {
      decimal,
      minValue: minValue(0),
    },
    monthlySalary: {
      required,
      decimal,
      minValue: minValue(SALARY_MIN_VALUE),
      maxValue: maxValue(SALARY_MAX_VALUE),
    },
  },
  computed: {
    disablePastDates(val) {
      return val >= new Date().toISOString().substr(0, 10);
    },
  },
  mounted() {
    this.handleGettingClinetsList("");
  },
  methods: {
    ...mapActions({
      getClientsList: "talentProfile/getClientsList",
      addClientToTalent: "talentProfile/addClientToTalent",
      updateContract: "contracts/updateContract",
    }),
    resetModal() {
      this.dialog = false;
      this.$v.$reset();
      this.monthlySalary = null;
      this.searchQuery = null;
      this.startDate = null;
      this.endData = null;
      this.contractType = null;
      this.monthlySalary = null;
      this.margin = "auto";
      this.jobType = null;
      this.raise = null;
    },
    submitForm() {
      this.$v.$touch();
      if (!this.$v.$anyError) {
        this.handleAddingClientToTalent();
      }
    },
    handleAddingClientToTalent() {
      this.isLoadingSubmitte = true;
      const data = {
        contract_type: this.contractType,
        start_date: this.startDate,
        end_date: this.endData,
        job_type: this.jobType,
        monthly_salary: this.monthlySalary,
        margin: this.margin,
        wht: this.wht,
        notes: "",
        raise: this.raise,
      };

      let payload = {
        contractId: this.contract.id,
        data,
      };

      this.updateContract(payload)
        .then(() => {
          this.isLoadingSubmitte = false;
          this.$emit("contractUpdated");
          this.dialog = false;
          this.resetModal();
        })
        .catch((err) => {
          this.isLoadingSubmitte = false;
          console.log(err);
        })
        .finally(() => {
          this.isLoadingSubmitte = false;
        });
    },
    handleGettingClinetsList(payload) {
      this.getClientsList(payload)
        .then(() => {
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<style lang="scss">
.v-select-input__contract-type input {
  cursor: pointer;
}

.v-input--is-disabled {
  cursor: not-allowed;

  .v-text-field__details {
    display: none;
  }

  .v-input__slot {
    cursor: not-allowed;
    margin-bottom: 0;
  }
}
</style>
